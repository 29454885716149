import axios from "@/lib/axios";
import { clone, resetObjectRecursive } from "@/lib/utils";
import { defineStore } from "pinia";

interface State {
	messages: Message[];
	waitingResponse: boolean;
	portfolioQuestionData: PortfolioQuestionData;
}

export interface Message {
	id: number;
	text: string;
	isQuestion: boolean;
}

export interface PortfolioQuestionData {
	query: string | null;
	portfolioName: string | null;
	portfolioTickers: string[];
}

const DEFAULT_PORTFOLIO_QUESTION_DATA = {
	query: null,
	portfolioName: null,
	portfolioTickers: [],
};

export const portfolioSpecificQuestions = [
	"Portföyümdeki hisselerin cari oranı nedir?",
	"Portföyümdeki şirketlerin nakit oranları nasıl?",
	"Portföyümdeki şirketlerin toplam öz kaynakları nedir?",
	"Hangi hisse senetleri şu an en yüksek pd/dd oranına sahip?",
	"F/K oranı en düşük olan hisse senedi hangisi?",
	"Portföydeki her bir şirketin ödenmiş sermayesi nedir?",
	"Portföyümdeki hisselerin son bir yıldaki getiri oranı nedir?",
	"Portföyümdeki hisselerin geçmiş fiyatlarına göre en çok değer kazananlar hangileri?",
	"Portföyümdeki şirketlerin dönem net kar/zarar oranı nedir?",
	"Portföyde en fazla net kar elde eden şirket hangisi?",
	"Varlıklar toplamı en yüksek olan şirket hangisi?",
	"Portföydeki şirketlerin kısa vadeli yükümlülükleri nelerdir?",
	"Portföyümdeki hisselerin nakit ve nakit benzerleri nedir?",
	"Portföyümdeki en karlı hisse senetleri hangileri?",
	"F/K oranı en yüksek olan hisse senedi nedir?",
	"Portföyümdeki hisselerin temettü verimliliği nedir?",
	"Portföyümdeki şirketlerin son bilanço durumu nedir?",
	"Hangi hisse senetleri portföyde en yüksek öz kaynak oranına sahip?",
	"Portföyümdeki hisseler arasında son günde en çok değer kaybedenler hangileri?",
	"Portföydeki hisse senetlerinin toplam varlıkları nedir?",
	"Portföydeki en düşük cari orana sahip hisse senedi nedir?",
];

export const useChatbotStore = defineStore("chatbot", {
	state: (): State => ({
		messages: [],
		waitingResponse: false,

		portfolioQuestionData: clone(DEFAULT_PORTFOLIO_QUESTION_DATA),
	}),
	actions: {
		async getStreamResponse(
			query: string,
			msgId: number,
			portfolioObj?: PortfolioQuestionData,
		) {
			await axios
				.post(
					`/chatbot/${portfolioObj ? "portfolio-response" : "response"}`,
					{
						...(portfolioObj
							? {
									query: portfolioObj?.query,
									portfolioTickers: portfolioObj?.portfolioTickers,
									portfolioName: portfolioObj?.portfolioName,
								}
							: { query: query }),
						stream: true,
					},
					{
						headers: {
							Accept: "text/event-stream",
						},
						responseType: "stream",
						adapter: "fetch",
						timeout: 10000,
					},
				)
				.then(async (response) => {
					const stream = response.data;

					// consume response
					const reader = stream
						.pipeThrough(new TextDecoderStream())
						.getReader();
					while (true) {
						const { value, done } = await reader.read();
						if (done) break;
						if (value) {
							for (let i = 0; i < this.messages.length; i++) {
								if (this.messages[i].id === msgId) {
									this.messages[i].text += value.replace(/\n/g, "");
								}
							}
						}
					}
				});
		},
		RESET_FORM_STATE() {
			resetObjectRecursive(
				this.portfolioQuestionData,
				DEFAULT_PORTFOLIO_QUESTION_DATA,
			);
		},
	},
});
