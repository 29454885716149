import BaseFormatter from "@/lib/formatter";
import { isObject } from "@/lib/utils";
import en from "@/locales/en";
import tr from "@/locales/tr";
import { useAuthStore } from "@/store";
import { computed } from "vue";

export type Language = "en" | "tr";
export const LANGUAGES: Language[] = ["en", "tr"];
export const DEFAULT_LANG = "en";
export function isLang(value: unknown): value is Language {
	return LANGUAGES.includes(value as Language);
}
const ALL_TRANSLATIONS = Object.fromEntries(
	LANGUAGES.map((lang) => [lang, loadTranslation(lang)]),
);
const WARNINGS = !import.meta.env.PROD;
const WARN = (...args: string[]) => console.warn("[i18n]", ...args);
const formatter = new BaseFormatter();

export default function useTranslate() {
	const authStore = useAuthStore();

	/**
	 * Writable computed language value
	 */
	const language = computed<Language>({
		get: () => authStore.language,
		set: (value) => {
			if (LANGUAGES.includes(value)) {
				authStore.changeLanguage({ language: value });
				document.documentElement.lang = value;
			} else {
				WARNINGS && WARN(`Language ${value} is not LANGUAGES.`);
			}
		},
	});

	if (!LANGUAGES.includes(language.value)) {
		language.value = DEFAULT_LANG;
	}
	document.documentElement.lang = language.value;

	/**
	 * Nested composable that returns computed value
	 * @param {string} key Keyword to translate
	 * @param  {...any} params params to replace in the translation
	 * @returns Translated value. If using outside component, use as t().value
	 */
	type LangTranslationPair = {
		[key in Language]: string;
	};
	const t = (key: string | LangTranslationPair, ...params: unknown[]) => {
		return computed<string>(() => {
			if (!key) {
				return "";
			}

			// if key is object of lang: key pairs, return the value of current language
			if (isObject(key)) {
				return key[language.value];
			}

			const translation = findTranslationOf(key, language.value);

			if (!translation) {
				WARNINGS && WARN("Cannot match:", key);
				return import.meta.env.PROD ? key : `[${key}]`;
			}

			// Parse the parameters for RegExp translation:
			if (translation.key instanceof RegExp) {
				let param = null;

				if (params.length) {
					const typeError =
						"Parameter need to be an object or a function for RegExp matches:";

					if (params.length > 1) {
						WARNINGS && WARN(typeError, key);
					} else {
						param = params[0];

						// params[0] is a mapper
						if (typeof param === "function") {
							param = param(translation.params);
						}

						// passed params[0] or mapper result should be a plain object
						if (!isObject(param)) {
							WARNINGS && WARN(typeError, key);
							param = null;
						}
					}
				}

				// Merge mapper results or plain param object with passed params
				params = [Object.assign({}, translation.params, param)];
			}

			if (
				params.length &&
				!(params.length === 1 && typeof params[0] === "function")
			) {
				return formatter.interpolate(translation.value, ...params);
			}
			return translation.value;
		});
	};

	/**
	 * Check if the passed value is exists in the translations
	 * @param {string} key Keyword to check translate
	 * @returns {Boolean} True if translation exists
	 */
	const checkTranslate = (key: any) => {
		if (!key) return false;
		const translation = findTranslationOf(key, language.value);
		return Boolean(translation);
	};

	return { t, language, checkTranslate };
}

function findTranslationOf(key: string, language: string | number) {
	const { text, regexp } = ALL_TRANSLATIONS[language];

	if (text[key]) {
		return { key, value: text[key] };
	}

	for (let i = 0; i < regexp.length; i++) {
		const matched = key.match(regexp[i][0]);
		if (matched) {
			return { key: regexp[i][0], value: regexp[i][1], params: matched.groups };
		}
	}

	return null;
}

function loadTranslation(lang: string) {
	const all = lang === "en" ? en : tr;
	return {
		text: Object.fromEntries(all.filter(([key]) => typeof key === "string")),
		regexp: all.filter(([key]) => key instanceof RegExp),
	};
}
