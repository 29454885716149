import useTranslate from "@/composables/useTranslate";
import { useAuthStore, usePortfolioStore } from "@/store";
import type { AnyObject } from "@/types/HelperTypes";

/**
 * Lazy cloning.
 * @param   {Object} obj
 * @returns {Object}
 */
export function clone<T>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}
/**
 * Parses JWT payload.
 * @param   {String} token
 * @returns {Object}
 */
export function parseJWT(token: string) {
	return JSON.parse(decodeURIComponent(escape(atob(token.split(".")[1]))));
}
/**
 * @param   {Mixed}   thing
 * @returns {Boolean}
 */
export function isObject(thing: unknown): thing is AnyObject {
	return thing !== null && typeof thing === "object" && !Array.isArray(thing);
}

export function escapeRegex(str: string) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

// TODO: The functions below will be moved to composable
export function parseSymbol(symbol: string) {
	const reg = new RegExp(
		"" +
			"^(?<code>.+)" +
			"\\.(?<domain>[A-Z]{1,2})" +
			"\\.(?<exchange>[^.-]+)" +
			"(-(?<identifier>.+))?" +
			"$",
	);
	const match = symbol.match(reg);
	return match ? match.groups : undefined;
}

export function getSymbolCode(symbol) {
	return parseSymbol(symbol)?.code;
}

export function estimatorName(key) {
	const portfolioStore = usePortfolioStore();
	const { t } = useTranslate();
	const estimator = portfolioStore.ALL_ESTIMATORS.find((i) => i.key === key);
	if (estimator) {
		return t(estimator.name).value;
	}
	return null;
}

export function objectiveName(key) {
	const portfolioStore = usePortfolioStore();
	const { t } = useTranslate();

	const objective = portfolioStore.ALL_OBJECTIVES.find((i) => i.key === key);
	if (objective) {
		return t(objective.name).value;
	}
	return null;
}

export function riskName(key) {
	const portfolioStore = usePortfolioStore();
	const { t } = useTranslate();

	const risk = portfolioStore.ALL_RISKS.find((i) => i.key === key);
	if (risk) {
		return t(risk.name).value;
	}
	return null;
}

const defaultChartColors = [
	"#001219",
	"#005F73",
	"#0A9396",
	"#94D2BD",
	"#E9D8A6",
	"#EE9B00",
	"#CA6702",
	"#BB3E03",
	"#AE2012",
	"#9B2226",
];

export function getChartColor(index) {
	return defaultChartColors[index % defaultChartColors.length];
}

export function formatCurrency(
	val: number,
	options: Intl.NumberFormatOptions & { locale?: string } = {},
) {
	const authStore = useAuthStore();
	const showCurrency = authStore.userPlan?.details?.showCurrency === "enabled";
	if (!showCurrency) {
		delete options.currency;
	}
	const format: Intl.NumberFormatOptions = {
		style: "currency",
		currencyDisplay: "narrowSymbol",
		...options,
	};
	const locale = options.locale || "en-US";
	// if no currency provided, then remove currency symbol
	if (!options.currency) {
		format.currency = "USD";
		format.currencyDisplay = "code";
	}
	const formatted = new Intl.NumberFormat(locale, format).format(val || 0);
	// if no currency provided, then remove currency symbol
	if (!options.currency) {
		return formatted.replace(/USD/g, "").trim();
	}
	return formatted;
}

export function getCurrencySymbol(code: string) {
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: code,
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
		currencyDisplay: "narrowSymbol",
	});
	return formatter.formatToParts(0).find((part) => part.type === "currency")
		?.value;
}

export function roundNumber(number: number, precision = 0) {
	const factor = 10 ** precision;
	return Math.round(number * factor) / factor;
}

/**
 * Reset object recursively without changing the reference. This function
 * will remove any keys that are not in the default object.
 * @param {Object} obj Destination object
 * @param {Object} defaultObj Default object
 */

export const resetObjectRecursive = (obj: AnyObject, defaultObj: AnyObject) => {
	for (const key in obj) {
		if (isObject(obj[key]) && isObject(defaultObj[key])) {
			resetObjectRecursive(obj[key], defaultObj[key]);
		} else {
			delete obj[key];
		}
	}
	for (const key in defaultObj) {
		if (isObject(defaultObj[key])) {
			if (!obj[key]) {
				obj[key] = {};
			}
			resetObjectRecursive(obj[key], defaultObj[key]);
		} else {
			obj[key] = defaultObj[key];
		}
	}
};
/**
 * Delete the given key recursively from given object
 * @param {Object} obj Object to changed
 * @param {String} key String key to delete
 */
export const deleteKeyRecursive = (obj: AnyObject, key: string) => {
	for (const k in obj) {
		if (k === key) {
			delete obj[k];
		}
		if (isObject(obj[k])) {
			deleteKeyRecursive(obj[k], key);
		}
	}
};

/**
 * Compare two array of values and return the difference boolean array.
 * [1, 2, 3] and [1, 2, 4] will return [false, false, true]
 */
export const compareArrayValues = (a: any[], b: any[]) => {
	return a.map((item, index) => item !== b[index]);
};

// https://stackoverflow.com/a/14224813/8017663
export function scaleValue(
	value: number,
	[fromMin, fromMax]: [number, number],
	[toMin, toMax]: [number, number],
	inverted?: boolean,
): number {
	const scaled =
		((value - fromMin) * (toMax - toMin)) / (fromMax - fromMin) + toMin;
	if (inverted) return toMax - scaled + toMin;
	return scaled;
}

export function getRestApiURL() {
	const matriksReg = /^https?:\/\/matriks\./;
	const appReg = /^https?:\/\/app\./;

	let { VITE_REST_API: url } = import.meta.env;

	if (window.location.origin.match(matriksReg)) {
		url = url.replace(appReg, "https://matriks.");
	}

	return url;
}

export function getSocketApiURL() {
	const matriksReg = /^https?:\/\/matriks\./;
	const appReg = /^https?:\/\/app\./;

	let { VITE_SOCKET_API: url } = import.meta.env;

	if (window.location.origin.match(matriksReg)) {
		url = url.replace(appReg, "https://matriks.");
	}

	return url;
}
