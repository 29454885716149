import { _adapters } from "chart.js";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import querterOfYear from "dayjs/plugin/quarterOfYear";
import weekday from "dayjs/plugin/weekday";
dayjs.extend(querterOfYear);
dayjs.extend(isoWeek);
dayjs.extend(weekday);

const FORMATS = {
	datetime: "MMM d, yyyy, h:mm:ss aaaa",
	millisecond: "h:mm:ss.SSS aaaa",
	second: "h:mm:ss aaaa",
	minute: "h:mm aaaa",
	hour: "ha",
	day: "MMM d",
	week: "PP",
	month: "MMM yyyy",
	quarter: "qqq - yyyy",
	year: "yyyy",
};

_adapters._date.override({
	_id: "day.js", // DEBUG

	formats: () => FORMATS,

	parse: (value) => {
		if (value === null || typeof value === "undefined") {
			return null;
		}
		const type = typeof value;
		if (type === "string" || type === "number" || value instanceof Date) {
			value = dayjs(value).toDate();
		}
		return dayjs(value).isValid() ? dayjs(value).valueOf() : null;
	},

	format: (time, fmt) => dayjs(time).format(fmt),

	add: (time, amount, unit) => dayjs(time).add(amount, unit).valueOf(),

	diff: (max, min, unit) => dayjs(max).diff(dayjs(min), unit),

	startOf: (time, unit, weekday) => {
		switch (unit) {
			case "isoWeek":
				return dayjs(time).isoWeekday(weekday).startOf("isoWeek").toDate();
			default:
				return dayjs(time).startOf(unit).toDate();
		}
	},

	endOf: (time, unit) => dayjs(time).endOf(unit).toDate(),
});
