import * as store from "@/store";
import { io } from "socket.io-client";
import { getRestApiURL, getSocketApiURL } from "./utils";
const { VITE_SOCKET_API, VITE_SOCKET_PATH } = import.meta.env;

const instance = io(getSocketApiURL() as string, {
	path: VITE_SOCKET_PATH,
	autoConnect: false,
});

let retry = 0;
// Example: { useAuthStore: { instance, events: ["SOCKET_connect"] }, useMainStore: {...} }
const storeInstances: {
	[key: string]: {
		instance: any;
		events: string[];
	};
} = {};

const socketHandler = (event: string, data: unknown = null) => {
	for (const key in storeInstances) {
		const { instance, events } = storeInstances[key];
		if (events.includes(event)) {
			instance[`SOCKET_${event}`](data);
		}
	}
};

instance.once("connect", () => {
	if (!Object.keys(storeInstances).length) {
		for (const key in store) {
			const instance = store[key]();
			storeInstances[key] = {
				instance,
				events: Object.keys(instance)
					.filter((key) => key.startsWith("SOCKET_"))
					.map((key) => key.replace("SOCKET_", "")),
			};
		}
	}
});

instance.onAny(socketHandler);

instance.on("connect_error", (error) => {
	socketHandler("connect_error", error);
	const authStore = store.useAuthStore();
	if (retry < 3 && error && error.message === "Token expired") {
		setTimeout(async () => {
			console.warn("trying to reconnect");
			// try to reconnect by updating token if its expired.
			await authStore.refreshAccessToken();
			instance.open();
			retry++;
		}, 1000);
	}
	console.warn("CONNECT_ERROR", error);
});

instance.on("connect", () => {
	socketHandler("connect");
	retry = 0;
});

instance.on("disconnect", (reason) => {
	socketHandler("disconnect", reason);
	console.warn("SOCKET_DISCONNECTED. reason:", reason);
});

export default instance;
