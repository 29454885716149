import App from "@/app.vue";
import router from "@/router";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { configureRollbar } from "./lib/error-reporting";
import mixins from "./mixins";
import "@/lib/vee-validate";
import "@/styles/main.scss";
import "@/styles/font-awesome/css/fa.min.css";

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.mixin(mixins);
app.mount("#app");

configureRollbar(app);
