import { all as AllRules } from "@vee-validate/rules";
import { defineRule } from "vee-validate";

Object.keys(AllRules).forEach((rule) => {
	defineRule(rule, AllRules[rule]);
});

defineRule("min_length", (value, [length]) => {
	if (value && value.length < length) {
		return `Value must be at least ${length} length`;
	}
	return true;
});

defineRule("max_length", (value, [length]) => {
	if (value && value.length > length) {
		return `Value must be at most ${length} length`;
	}
	return true;
});
