import routes from "@/router/routes";
import { useMainStore } from "@/store";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { left: 0, top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	const mainStore = useMainStore();
	// TODO: check if tokens has expired and redirect to the login if they are
	mainStore.pageTitle = "";
	next();
});

export default router;
