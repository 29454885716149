export default class AppError extends Error {
	message = null;

	status = null;

	name = null;

	constructor({ message, status, name, ...others }) {
		super(message);
		// these are required
		this.message = message;
		this.status = status;
		this.name = name;
		// also assign every other stuff to this object directly
		Object.assign(this, others);
	}

	export() {
		return { ...this };
	}
}
