import axios from "@/lib/axios";
import type {
	EstimatorName,
	ModelName,
	ObjectiveName,
	Portfolio,
	RiskName,
} from "@dev-team/types";
import isMatch from "lodash/isMatch";
import { defineStore } from "pinia";

const OPTIMIZIATION_EXPIRY = 86400; // a day, in seconds
const defaultDocumentTitle = window.document.title;

type PortfolioApprovalStatus =
	| "creation"
	| "pending-acknowledgement"
	| "acknowledged"
	| "done";
interface State {
	detailRecord: Portfolio | null;

	model: ModelName | null;
	estimator: EstimatorName | null;
	objective: ObjectiveName | null;
	risk: RiskName | null;

	transactionFormData: { [key: string]: number };

	approving: boolean;
	status: PortfolioApprovalStatus | null;

	acknowledgement: {
		timedout: boolean | null;
		timeoutId: string | number | undefined | null;
	};

	approvalDialog: boolean;
}

export const usePortfolioApprovalStore = defineStore("portfolio-approval", {
	state: (): State => ({
		detailRecord: null,

		model: null,
		estimator: null,
		objective: null,
		risk: null,

		transactionFormData: {},
		approving: false,
		status: null,
		// Matriks acknowledgement status
		acknowledgement: {
			timedout: null,
			timeoutId: null,
		},

		approvalDialog: false,
	}),

	getters: {
		oldWeights: (state) => {
			const optimization = state.detailRecord?.optimization;

			if (!optimization) {
				return null;
			}

			const {
				approved: { model, estimator, objective, risk },
			} = optimization;
			const result = optimization.results?.find(
				(a) =>
					a.model === model &&
					a.estimator === estimator &&
					a.objective === objective &&
					a.risk === risk,
			);

			if (result) {
				return result.weights;
			}

			return [];
		},

		newWeights: (state) => {
			const selectedAll =
				state.model && state.estimator && state.objective && state.risk;
			const pendingOptimization = state.detailRecord?.pendingOptimization;

			if (!selectedAll || !pendingOptimization) {
				return null;
			}

			if (selectedAll && pendingOptimization) {
				const result = pendingOptimization.results
					?.filter((r) => !r.error)
					.find(
						(a) =>
							a.model === state.model &&
							a.estimator === state.estimator &&
							a.objective === state.objective &&
							a.risk === state.risk,
					);

				if (result) {
					return result.weights;
				}
			}

			return [];
		},

		erroredOptimization(state) {
			const selectedAll =
				state.model && state.estimator && state.objective && state.risk;
			const pendingOptimization = state.detailRecord?.pendingOptimization;

			if (pendingOptimization?.error) {
				return true;
			}

			if (!selectedAll || !pendingOptimization) {
				return null;
			}

			if (selectedAll && pendingOptimization) {
				const result = pendingOptimization.results
					?.filter((r) => !r?.error)
					.find(
						(a) =>
							a.model === state.model &&
							a.estimator === state.estimator &&
							a.objective === state.objective &&
							a.risk === state.risk,
					);

				if (!result) {
					return true;
				}
			}

			return false;
		},

		optimizationIsExpired(state) {
			const pendingOptimization = state.detailRecord?.pendingOptimization;
			if (
				!pendingOptimization ||
				!pendingOptimization.createdAt ||
				pendingOptimization.error
			) {
				return null;
			}
			const date = new Date(pendingOptimization.createdAt);
			return (Date.now() - date.getTime()) / 1000 > OPTIMIZIATION_EXPIRY;
		},
	},

	actions: {
		async setDetailRecord(record: Portfolio) {
			const results = record?.pendingOptimization?.results || [];
			this.detailRecord = record;

			// if there are some results, then find the one with current approved configuration.
			// else pick the first non-error result. lastly pick the first result.
			if (results.length) {
				let configFormData = null;
				const nonErrorResults = results.filter((item) => !item.error);

				const approvedConfig = record?.optimization?.approved;
				if (approvedConfig) {
					const resultWithApprovedConfig = results.find((r) =>
						isMatch(r, {
							model: approvedConfig.model,
							estimator: approvedConfig.estimator,
							objective: approvedConfig.objective,
							risk: approvedConfig.risk,
						}),
					);

					if (
						resultWithApprovedConfig &&
						// If approved config has error and there is a non-error result, use that.
						!(resultWithApprovedConfig.error && nonErrorResults.length)
					) {
						configFormData = resultWithApprovedConfig;
					}
				}

				if (!configFormData) {
					if (nonErrorResults.length) {
						configFormData = nonErrorResults[0];
					} else {
						configFormData = results[0];
					}
				}
				this.model = configFormData.model;
				this.estimator = configFormData.estimator;
				this.objective = configFormData.objective;
				this.risk = configFormData.risk;
			}
			this.FILL_TRANSACTION_FORM_DATA();
			return record;
		},

		async approve(integration: "Matriks" | "IdealData" | "Magnus") {
			const {
				model,
				estimator,
				objective,
				risk,
				transactionFormData,
				detailRecord,
			} = this;

			const portfolio = detailRecord?.id;

			const transaction = Object.entries(transactionFormData)
				.map(([symbol, pledge]) => ({ symbol, pledge }))
				.filter((i) => !!i.pledge);

			this.status = "creation";
			this.approving = true;
			try {
				const { data } = await axios.put("/portfolio/approve", {
					model,
					estimator,
					objective,
					risk,
					transaction,
					integration,
					portfolio,
				});

				if (integration === "Matriks" || integration === "IdealData") {
					this.status = "pending-acknowledgement";
				} else {
					this.status = "done";
					this.approving = false;
				}

				return data;
			} catch (err) {
				this.approving = false;
				throw err;
			}
		},

		async reject() {
			const { id } = this.detailRecord as Portfolio;
			return axios.put("/portfolio/reject", {
				portfolio: id,
			});
		},

		FILL_TRANSACTION_FORM_DATA() {
			const detailRecord = this.detailRecord;
			const results = detailRecord?.pendingOptimization?.results;
			const { model, estimator, objective, risk } = this;

			this.transactionFormData = {};
			if (!results) return;

			const result = results
				?.filter((r) => !r?.error)
				.find(
					(r) =>
						r.model === model &&
						r.estimator === estimator &&
						r.objective === objective &&
						r.risk === risk,
				);

			if (!result) {
				return null;
			}

			const transaction: { symbol: string; value: number }[] = [];
			const currentAssets: { [key: string]: number } = {};
			let investment = detailRecord.plannedInvestment;

			// if this is a rebalance
			if (detailRecord.optimization) {
				investment = detailRecord.state.balance;
			}

			detailRecord.state.assets.forEach(({ symbol, amount, price }) => {
				currentAssets[symbol] = amount * price;
			});

			const newSymbols = result.weights.map((i) => i.symbol);
			const missingSymbols = Object.keys(currentAssets).filter(
				(i) => !newSymbols.includes(i),
			);
			const weights = [
				...result.weights,
				...missingSymbols.map((symbol) => ({ symbol, value: 0 })),
			];

			weights.forEach(({ symbol, value }) => {
				transaction.push({
					symbol,
					value: value * investment - (currentAssets[symbol] || 0),
				});
			});

			transaction.forEach(({ symbol, value }) => {
				if (Math.abs(value) < 10) {
					value = 0;
				}
				this.transactionFormData[symbol] = Math.round(value * 100) / 100;
			});
		},

		SOCKET_matriksAcknowledged() {
			this.approving = false;
			this.status = "acknowledged";
			this.acknowledgement.timedout = false;
			if (this.acknowledgement.timeoutId) {
				clearTimeout(this.acknowledgement.timeoutId);
			}
			window.document.title = defaultDocumentTitle;
		},

		SOCKET_idealDataAcknowledged() {
			this.approving = false;
			this.status = "acknowledged";
			this.acknowledgement.timedout = false;
			if (this.acknowledgement.timeoutId) {
				clearTimeout(this.acknowledgement.timeoutId);
			}
		},
	},
});
