import Rollbar from "rollbar";
import * as store from "../store";

const stores = [
	"useAuthStore",
	"useNotificationStore",
	"usePortfolioApprovalStore",
	"usePortfolioStore",
	"useSimulationStore",
	"useTickerStore",
];

const IS_PROD = import.meta.env.PROD;
const ROLLBAR_TOKEN = import.meta.env.VITE_ROLLBAR_TOKEN;

function extractState() {
	// some of the store functions throw error if its not initialized yet.
	// so we have to try to extract it in try-catch.
	const result: { [key: string]: unknown } = {};
	for (const storeItem of stores) {
		try {
			result[storeItem] = store[storeItem]().$state;
		} catch (err) {
			// .. do nothing
		}
	}
	return JSON.parse(JSON.stringify(result));
}

export function configureRollbar(app) {
	if (!ROLLBAR_TOKEN) return;
	app.config.globalProperties.$rollbar = new Rollbar({
		accessToken: ROLLBAR_TOKEN,
		captureUncaught: true,
		captureUnhandledRejections: true,

		payload: {
			code_version: "1.0.0",
			environment: IS_PROD ? "production" : "development",
			source_map_enabled: true,
			client: {
				javascript: {
					code_version: "1.0.0",
					source_map_enabled: true,
				},
			},
		},
		transform: (payload) => {
			payload.state = extractState();
		},
	});

	app.config.errorHandler = (err, vm, info) => {
		vm.$rollbar.error(err);
		throw err;
	};
}
