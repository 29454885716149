import dayjs from "@/lib/dayjs";
import { computed, unref } from "vue";
import useTranslate from "./useTranslate";

export default function useDayjs() {
	const { language } = useTranslate();

	// // TODO: remove below if this issue ever gets resolved: https://github.com/iamkun/dayjs/issues/1227
	// dayjs.tz.setDefault("UTC");
	// // eslint-disable-next-line @typescript-eslint/no-explicit-any
	// const timezonedDayjs = (...args: any[]) => dayjs(...args).tz("UTC", true);
	// const timezonedUnix = (value: number) => dayjs.unix(value).tz("UTC", true);
	// timezonedDayjs.unix = timezonedUnix;
	// timezonedDayjs.locale = dayjs.locale;
	// timezonedDayjs.tz = dayjs.tz;

	return computed(() => {
		dayjs.locale(unref(language));
		return dayjs;
	});
}
