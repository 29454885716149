import useDayjs from "@/composables/useDayjs";
import useTranslate from "@/composables/useTranslate";
import axios from "@/lib/axios";
import type {
	Country,
	Currency,
	Domain,
	Exchange,
	Indice,
	Record,
	Sector,
	Ticker,
} from "@dev-team/types";
/* eslint-disable prefer-const */
import { defineStore } from "pinia";
import { type ComputedRef, toRef } from "vue";
import { useAuthStore, usePortfolioStore } from ".";

/**
 * @typedef {Object} Ticker
 * @property {string} availableFrom
 * @property {string} availableTo
 * @property {string} code
 * @property {string} country
 * @property {string} currency
 * @property {string} domain
 * @property {string} exchangeKey
 * @property {number} historicalDataCoverage
 * @property {Array<string>} indexKeys
 * @property {string} name
 * @property {number} price
 * @property {string} priceUpdatedAt
 * @property {string} sectorKey
 * @property {string} symbol
 */

// biome-ignore format: keep the file short
export const EU_COUNTRY_CODES = ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"];
// biome-ignore format: keep the file short
export const AS_COUNTRY_CODES = ["AE", "AF", "AM", "AZ", "BD", "BH", "BN", "BT", "CN", "CY", "GE", "HK", "ID", "IL", "IN", "IQ", "IR", "JO", "JP", "KG", "KH", "KP", "KR", "KW", "KZ", "LA", "LB", "LK", "MM", "MN", "MO", "MV", "MY", "NP", "OM", "PH", "PK", "QA", "SA", "SG", "SY", "TH", "TJ", "TL", "TM", "TW", "UZ", "VN", "YE"];

interface MarketMeta {
	key: string;
	name: ComputedRef<string>;
	indexCountries?: string[];
	domains?: string[];
}
interface CryptoExchange {
	name: string;
	value: string;
}

interface DomainMeta {
	name: string;
	value: string;
	nameSingle?: string;
	countryFilter?: boolean;
	sectorFilter?: boolean;
}

export const MARKETS = (): MarketMeta[] => {
	const { t } = useTranslate();
	return [
		{
			key: "US",
			name: t("US"),
			indexCountries: ["US"],
		},
		{
			key: "EU",
			name: t("Europe"),
			indexCountries: EU_COUNTRY_CODES,
		},
		{
			key: "AS",
			name: t("Asia"),
			indexCountries: AS_COUNTRY_CODES,
		},
		{
			key: "TR",
			name: t("Turkey"),
			indexCountries: ["TR"],
			domains: ["F"],
		},
		{
			key: "FX",
			name: t("Exchange Rates"),
		},
		{ key: "CR", name: t("Crypto") },
	];
};

export const CRYPTO_EXCHANGES = (): CryptoExchange[] => {
	return [
		{
			name: "Binance",
			value: "BINANCE",
		},
		{
			name: "BTCTurk",
			value: "BTCTURK",
		},
		{
			name: "Paribu",
			value: "PARIBU",
		},
	];
};

export const DOMAINS = (): DomainMeta[] => {
	const { t } = useTranslate();
	return [
		{
			name: t("All").value,
			value: "all",
		},
		{
			name: t("Stocks").value,
			nameSingle: t("Stock").value,
			value: "E",
			countryFilter: true,
			sectorFilter: true,
		},
		{
			name: t("Funds").value,
			nameSingle: t("Fund").value,
			value: "F",
			countryFilter: true,
		},
		{
			name: t("Futures").value,
			nameSingle: t("Future").value,
			value: "FT",
			countryFilter: true,
		},
		{
			name: t("Forex").value,
			nameSingle: t("Forex").value,
			value: "X",
		},
		{
			name: t("Crypto").value,
			nameSingle: t("Crypto").value,
			value: "CR",
		},
		{
			name: t("Indices").value,
			nameSingle: t("Indice").value,
			value: "I",
			countryFilter: true,
		},
	];
};

interface State {
	loading: number;

	search: string;
	selectedSectors: string[];
	selectedIndexes: string[];
	selectedExchanges: string[];
	selectedDomains: string[];
	selectedMarket: string;

	page: number;
	size: number;
	sort: string | undefined;
	asc: boolean;
	totalSize: number;

	tickers: Ticker[];
	indexes: Indice[];
	sectors: Sector[];
	exchanges: Exchange[];
	domains: Domain[];
	countries: Country[];
	currencies: Currency[];

	MARKETS: MarketMeta[];
}

export const useTickerStore = defineStore("ticker", {
	state: (): State => ({
		loading: 0,

		search: "",
		selectedSectors: [],
		selectedIndexes: [],
		selectedExchanges: [],
		selectedDomains: [],
		selectedMarket: MARKETS()[0].key,

		page: 1,
		size: 50,
		sort: undefined,
		asc: true,
		totalSize: 0,

		tickers: [],
		indexes: [],
		sectors: [],
		exchanges: [],
		domains: [],
		countries: [],
		currencies: [],

		MARKETS: MARKETS(),
	}),

	getters: {
		userAllowedIndexes(state) {
			const authStore = useAuthStore();
			if (!authStore.userPlan) return [];

			return state.indexes.filter((index) =>
				authStore.userPlan?.details.tickerIndexes.includes(index.key),
			);
		},
	},

	actions: {
		async loadEntities() {
			this.loading += 1;
			try {
				let {
					data: { sectors, indices, exchanges, domains, countries, currencies },
				} = await axios.get("/dataprovider/entities");
				indices = await Promise.all(
					indices.map(async (index: Indice) => {
						const details = await this.getTickerDetail(index.ticker);
						return {
							...index,
							...details,
						};
					}),
				);
				this.$patch({
					sectors,
					indexes: indices,
					exchanges,
					domains,
					countries,
					currencies,
				});
			} catch (err) {
				console.error(err);
			}
			this.loading -= 1;
		},

		/**
		 * loadTickers
		 * @param {Object} options Options object for loadTickers function.
		 * @param {string} options.startDate Filter tickers by available from this date.
		 * @param {string} options.endDate Filter tickers by available to this date.
		 * @param {boolean} options.mergeTickers If true, merge tickers with existing ones.
		 * @returns {Promise<{
		 *  list: Array<Ticker>,
		 *  meta: {
		 *    totalSize: number,
		 *    totalPage: number
		 *  }
		 * }>} tickers and meta data
		 */
		async loadTickers({
			startDate,
			endDate,
			mergeTickers = false,
			stats,
		}: {
			startDate: string;
			endDate: string;
			mergeTickers: boolean;
			stats?: any;
		}) {
			this.loading += 1;

			try {
				const {
					selectedIndexes: indexes,
					selectedSectors: sectors,
					selectedExchanges: exchanges,
					selectedDomains: domains,
					selectedMarket,
					page,
					size,
					sort,
					asc,
					search,
				} = this;

				const countries = this.MARKETS.find(
					(market) => market.key === selectedMarket,
				)?.indexCountries;

				const { data } = await axios.post("/dataprovider/available-tickers", {
					page,
					size,
					sort,
					asc,
					startDate,
					endDate,
					indexes: indexes.length ? indexes : undefined,
					sectors: sectors.length ? sectors : undefined,
					exchanges: exchanges.length ? exchanges : undefined,
					domains: domains.length ? domains : undefined,
					countries: countries ? countries : undefined,
					search,
					stats,
				});

				if (mergeTickers) {
					this.tickers.push(...data.list);
				} else {
					this.tickers = data.list;
				}
				this.loading -= 1;
				this.totalSize = data.meta.totalSize;

				return data;
			} catch (err) {
				console.error(err);
				this.loading -= 1;
			}
		},

		async loadNextPage() {
			const portfolioStore = usePortfolioStore();

			const startDate = toRef(portfolioStore.formData, "startDate");
			const endDate = toRef(portfolioStore.formData, "endDate");

			try {
				this.page++;

				const data = await this.loadTickers({
					startDate: startDate.value,
					endDate: endDate.value,
					mergeTickers: true,
				});

				if (!data.list.length) {
					this.page--;
				}
			} catch (e) {
				console.error(e);
			}
		},

		async fetchLatestPricesOf(symbols: string[]) {
			if (!symbols.length) {
				return {};
			}
			const { data } = await axios.post("/dataprovider/tickers", {
				symbols,
			});
			return Object.fromEntries(
				data.list.map(
					({ symbol, price }: { symbol: string; price: number }) => [
						symbol,
						price,
					],
				),
			);
		},

		async fetchHistoricalPriceData({
			symbols,
			start,
			end,
			sort,
			currency,
		}: {
			symbols: string[];
			start: string;
			end: string;
			sort: string;
			currency: string;
		}) {
			const { data } = await axios.get<Record[]>(
				"/dataprovider/historical-price-data",
				{
					params: { symbols, start, end, sort, currency },
				},
			);
			return data;
		},

		async getTickerDetail(symbol: string) {
			const dayjs = useDayjs();
			try {
				const historical = await axios.get(
					"/dataprovider/historical-price-data",
					{
						params: {
							symbols: symbol,
							start: dayjs.value().subtract(1, "week").format("YYYY-MM-DD"),
							end: dayjs.value().format("YYYY-MM-DD"),
							sort: "-date",
						},
					},
				);

				const data: {
					marketCap?: number;
					price?: number;
					previousPrice?: number;
				} = {
					marketCap: undefined,
					price: undefined,
					previousPrice: undefined,
				};
				if (historical.data.length > 1) {
					data.marketCap = historical.data[0].marketCap;
					data.price = historical.data[0].price;
					for (const record of historical.data.slice(1)) {
						const diff = Math.abs(
							dayjs.value(record.date).diff(dayjs.value(), "day"),
						);
						if (diff > 0) {
							data.previousPrice = record.price;
							break;
						}
					}
				}
				return data;
			} catch (err) {
				if (err instanceof Error) {
					if (err.name === "CanceledError") return;
				}
				console.error(err);
			}
		},

		RESET_STATE() {
			this.$patch({
				search: "",
				selectedSectors: [],
				selectedIndexes: [],
				selectedExchanges: [],
				selectedDomains: [],
			});
		},
	},
});
